$bg-yellow: rgb(255, 212, 42)
$dirty-yellow: rgba(137, 160, 44, 0.3)
$bg-grey: rgb(227, 222, 219)

$font: 'Montserrat', sans-serif

$icomoon-font-family: "icomoon" !default
$icomoon-font-path: "../fonts" !default

$portfolio-arrow-icon: "\e900"
$portfolio-exit-icon: "\e901"
$portfolio-github-icon: "\e902"
$portfolio-linkdin-icon: "\e903"
$portfolio-message-icon: "\e904"