@import "variables"

*
    margin: 0
    padding: 0
    box-sizing: border-box
    color: white
body
    background-color: $bg-grey

a
    text-decoration: none
    color: white
.seo-title
    display: none

@font-face 
    font-family: '#{$icomoon-font-family}'
    src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?x4p0ud')
    src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?x4p0ud#iefix') format('embedded-opentype'), url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?x4p0ud') format('truetype'), url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?x4p0ud') format('woff'), url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?x4p0ud##{$icomoon-font-family}') format('svg')
    font-weight: normal
    font-style: normal
    font-display: block


[class^="portfolio-"], [class*=" portfolio-"] 
    font-family: '#{$icomoon-font-family}' !important
    speak: never
    font-style: normal
    font-weight: normal
    font-variant: normal
    text-transform: none
    line-height: 1
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale


.portfolio-arrow-icon 
    &:before 
        content: $portfolio-arrow-icon
  

.portfolio-exit-icon 
    &:before 
        content: $portfolio-exit-icon
  

.portfolio-github-icon 
    &:before 
        content: $portfolio-github-icon
  

.portfolio-linkdin-icon 
    &:before 
        content: $portfolio-linkdin-icon
  

.portfolio-message-icon 
    &:before 
        content: $portfolio-message-icon
  


